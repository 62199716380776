import React from 'react';

const Skills = (props) => {
    return (
        <div className="skills p-t-10px-sm p-r-30px">
            {props.children}
        </div>
    );
};

export default Skills;
